import { id as $vuetify } from 'vuetify/locale';

const id = {
  auth: {
    logout: 'Keluar',
    register: 'Daftar',
    optional: 'Opsional',
    password: 'Password',
    setPassword: 'Buat Password',
    sendingCode: 'Sedang Mengirim Kode',
    noCodeQuestion: 'Tidak menerima kode?',
    codeSent: 'Kode verifikasi telah dikirim ke {slot}',
    codeSentSub: 'Masukkan kode tersebut untuk melanjutkan',
    loginWith: 'Masuk Dengan {slot}',
    withAccount: 'dengan akun',
    chooseAccount: 'Pilih Akun',
    resetSuccess: 'Reset Password Berhasil',
    oldPassword: 'Password Lama',
    newPassword: 'Password Baru',
    enterPassword: 'Masukkan Password',
    enterPasswordMessage: 'Untuk keamanan akun, masukkan password kamu terlebih dahulu',
    resetPassword: 'Reset Password',
    repeatPassword: 'Konfirmasi Password',
    createPassword: 'Buat Kata Sandi',
    createPasswordMessage: 'Lanjutkan proses dengan membuat password MyValue',
    errorRegistered: 'Akun sudah terdaftar',
    emailOrPhone: 'E-Mail / Nomor HP',
    emailOrPhoneNotFound: 'E-Mail / Nomor HP tidak terdaftar',
    requestCodeIn: 'Minta ulang kode verifikasi dalam',
    fullfillProfile: 'Verifikasi dan lengkapi profil akun Anda',
    passResetSuccess: 'Password berhasil direset. Silahkan login menggunakan password baru',
    passResetConfirmTitle: 'Otomatis keluar pada perangkat lain',
    passResetConfirmSubtitle: 'Setelah pengubahan password, kamu akan otomatis keluar pada perangkat lain',
    changeEmailTitle: 'Ubah E-Mail',
    changeEmailSubtitle: 'Masukkan E-Mail baru yang akan digunakan',
    changePhoneNoIntl: 'Nomor International harus melalui CS',
    changePhoneTitle: 'Ubah Nomor HP',
    changePhoneSubtitle: 'Masukkan Nomor HP baru yang akan digunakan',
    changeCredSuccess: '{slot} Berhasil Diubah',
    changeCredMessage: 'Kamu dapat melakukan login ulang dengan menggunakan identitas yang baru',
  },
  account: {
    segs: 'Jenis Kelamin',
    detail: 'Detail Akun',
    occupation: 'Pekerjaan',
    myPoints: 'Poin Saya',
    pointHistory: 'Riwayat Poin',
    myCards: 'Kartu Saya',
    referral: 'Referral',
    birthDate: 'Tanggal Lahir',
    editSuccess: 'Profil Berhasil Diubah',
    editProfile: 'Ubah Profil',
    changePhone: 'Ubah Nomor HP',
    changeEmail: 'Ubah E-Mail',
    changePassword: 'Ubah Password',
    passwordChanged: 'Password Berhasil Diubah',
    entryVerified: '{slot} Terverifikasi',
    entryUnverified: '{slot} Belum Terverifikasi',
    profilePhoto: 'Foto Profil',
    profilePhotoChange: 'Ubah Foto Profil',
    profilePhotoChanged: 'Foto Profil Berhasil Diubah',
    profileDetails: 'Detail Profil',
  },
  card: {
    add: 'Tambah Kartu',
    added: 'Kartu Berhasil Ditambahkan',
    title: 'Kartu Saya',
    number: 'Nomor Kartu',
    notFound: 'Kartu tidak ditemukan',
    notMatch: 'Jenis kartu tidak cocok, mungkin maksudmu kartu "{slot}"?',
    activation: 'Aktivasi Kartu',
    diffEmailTitle: 'Email pada kartu berbeda dengan email di akun kamu',
    diffEmailBody: 'Email yang terdaftar pada kartu adalah <b>{slot}</b>',
    diffEmailSub:
      'Kode aktivasi akan dikirim ke email terdaftar di kartu. Pastikan kamu masih dapat mengakses email tersebut.',
    lengthError: 'Nomor kartu harus {slot} digit',
    whereNumber: 'Nomor kartu dapat dilihat di belakang kartu',
  },
  errors: {
    genericTitle: 'Terjadi Kesalahan',
    genericWithRetry: 'Terjadi kesalahan, silahkan coba lagi nanti.',
  },
  generic: {
    to: 'Ke',
    from: 'Dari',
    or: 'Atau',
    back: 'Kembali',
    next: 'Lanjut',
    open: 'Buka',
    save: 'Simpan',
    name: 'Nama',
    male: 'Laki-Laki',
    female: 'Perempuan',
    later: 'Nanti Saja',
    second: 'Detik',
    resend: 'Kirim Ulang',
    submit: 'Kirim',
    city: 'Kabupaten/Kota',
    address: 'Alamat',
    district: 'Kecamatan',
    province: 'Provinsi',
    cancel: 'Batal',
    change: 'Ubah',
    close: 'Tutup',
    done: 'Selesai',
    uploadPhoto: 'Unggah Foto',
    toContinueTo: 'untuk melanjutkan ke',
    willExpireOn: 'akan kadaluarsa pada',
    privacyPolicy: 'Kebijakan Privasi',
    termsAndConditions: 'Syarat dan Ketentuan',
    minCharacters: 'Minimal {type} {slot} karakter',
    maxCharacters: 'Maksimal {type} {slot} karakter',
  },
  login: {
    title: 'Masuk',
    withMyValue: 'dengan akun MyValue anda',
    orLoginWith: 'atau masuk dengan',
    accountVerif: 'Verifikasi Akun',
    forgotPassword: 'Lupa Password?',
    noEmptyPassword: 'Password tidak boleh kosong',
    noEmptyUsername: 'E-Mail/Nomor HP tidak boleh kosong',
    dontHaveAccount: 'Belum memiliki akun MyValue?',
    notRegisteredToast: 'Akun tidak ditemukan, silahkan daftar terlebih dahulu',
  },
  mapping: {
    title: 'Hubungkan Akun',
    titleTemplate: 'Hubungkan {slot} dengan MyValue',
    invalid: 'Data koneksi akun tidak valid',
    subtitle:
      'Kami menemukan akunmu di MyValue, hubungkan akun {slot} mu dan dapatkan lebih banyak keuntungan',
    connect: 'Hubungkan Dengan MyValue',
    loginFirst: 'Silahkan login terlebih dahulu untuk menghubungkan akun',
    benefitOne: 'Dapatkan poin dengan beraktivitas di {slot} dan Kompas Gramedia',
    benefitTwo: 'Masuk lebih mudah di berbagai layanan Kompas Gramedia dengan MyValue',
    finishTitle: 'Dalam proses menghubungkan akunmu...',
    finishSubtitle: 'Halaman akan otomatis berpindah, jangan tutup halaman ini',
    finishNextCase:
      'Akun telah terhubung, selanjutnya kamu dapat masuk dengan akun MyValue.<br/><b>Tekan tombol lanjutkan untuk kembali ke halaman {slot}</b>.',
  },
  points: {
    allTransactions: 'Semua Transaksi',
    transactionDate: 'Tanggal Transaksi',
    transactionType: 'Jenis Transaksi',
    transactionSource: 'Sumber Transaksi',
  },
  referral: {
    create: 'Buat Kode Referral',
    createSuccess: 'Kode Referral Berhasil Dibuat',
    createMessage: 'Kamu dapat membuat kode yang semenarik mungkin',
    emptyState:
      'Mulai kumpulkan Value Friendmu dengan cara membagikan kode referralmu.<br /><a href="{slot}">Syarat dan Ketentuan Berlaku</a>',
    codeNotFound: 'Kode Referral tidak ditemukan',
  },
  register: {
    title: 'Daftar',
    success: 'Pendaftaran Berhasil',
    withOauth: 'Akun didaftarkan dengan {slot}',
    successMessage: 'Silahkan verifikasi akun Anda mendapatkan penawaran menarik dari MyValue',
    orRegisterWith: 'atau daftar dengan',
    haveAccount: 'Sudah memiliki akun MyValue?',
    errorUnameBlank: 'E-Mail/Nomor HP tidak boleh kosong',
    errorUnameInvalid: 'Format E-Mail/Nomor HP salah',
    errorEmailBlank: 'Email tidak boleh kosong',
    errorNameBlank: 'Nama tidak boleh kosong',
    errorPhoneBlank: 'Nomor HP tidak boleh kosong',
    errorPasswordLength: 'Password minimal {slot} karakter',
    errorPasswordMismatch: 'Password tidak sama',
    labelHP: 'Nomor HP',
    labelEmail: 'E-Mail',
    labelFullname: 'Nama Lengkap',
    labelReferral: 'Kode Referral',
    labelConfirmPass: 'Konfirmasi Password',
    newPasswordSuccess: 'Password berhasil dibuat',
    newPasswordSuccessMessage: 'Kamu dapat menggunakan password baru untuk masuk ke akun MyValue.',
    acceptTnC:
      'Dengan menekan tombol Daftar, Saya setuju dengan <a href="{slot}" target="_blank" style="color: {color}" noreferrer noopener>Syarat dan Ketentuan serta Kebijakan Privasi</a> dari aplikasi MyValue serta bersedia menerima email yang berisi informasi dan penawaran dari MyValue.',
  },
  verify: {
    now: 'Verifikasi Sekarang',
    email: 'Verifikasi E-Mail',
    phone: 'Verifikasi Nomor HP',
    later: 'Verifikasi Nanti',
    success: 'Akun Terverifikasi',
    successMessage: 'Sekarang kamu dapat menikmati seluruh manfaat dari akun MyValue.',
    verificationSent: 'Kode verifikasi telah dikirim',
    verificationResent: 'Kode verifikasi telah dikirim ulang',
    verificationMessage:
      'Kode verifikasi telah dikirim ke email <b class="text-primary">{slot}</b>. Masukkan kode tersebut untuk melanjutkan.',
    linkFormTitle: 'Satu langkah lagi untuk verifikasi akun kamu',
    linkWithReward: 'Detail hadiah akan dikirimkan ke email ini',
    linkWithRewardTitle: 'Cek email kamu sekarang untuk kesempatan mendapatkan hadiah',
    linkWithRewardSubtitle:
      'Terima kasih, nomor kamu telah terverifikasi. Kami telah mengirimkan email ke akun kamu, Mohon cek email untuk mendapatkan info lebih lanjut.',
  },
  $vuetify,
};

export default id;
