import type { I18NDictionary } from './types';

import { en as $vuetify } from 'vuetify/locale';

const en: I18NDictionary = {
  auth: {
    logout: 'Logout',
    register: 'Register',
    optional: 'Optional',
    password: 'Password',
    setPassword: 'Set Password',
    sendingCode: 'Sending Code',
    noCodeQuestion: "Didn't receive the code?",
    codeSent: 'Verification code sent to {slot}',
    codeSentSub: 'Enter the code to continue',
    loginWith: 'Login With {slot}',
    withAccount: 'with',
    chooseAccount: 'Choose an Account',
    resetSuccess: 'Password Reset Successful',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    enterPassword: 'Enter Password',
    enterPasswordMessage: 'For account security, please enter your password',
    resetPassword: 'Reset Password',
    repeatPassword: 'Confirm Password',
    createPassword: 'Create Password',
    createPasswordMessage: 'Continue by creating password for your account',
    errorRegistered: 'Account already registered',
    emailOrPhone: 'E-Mail / Phone Number',
    emailOrPhoneNotFound: 'E-Mail / Phone Number not registered',
    requestCodeIn: 'Request verification code again in',
    fullfillProfile: 'Verify and complete your account profile',
    passResetConfirmTitle: 'You Will Be Logged Out',
    passResetConfirmSubtitle:
      'After password changed successfully, you will be automatically logged out on other devices',
    passResetSuccess: 'Password reset successful. Please login using your new password',
    changeEmailTitle: 'Change E-Mail',
    changeEmailSubtitle: 'Enter the new E-Mail to be used',
    changePhoneNoIntl: 'Contact Customer Service for International Number',
    changePhoneTitle: 'Change Phone Number',
    changePhoneSubtitle: 'Enter the new Phone Number to be used',
    changeCredSuccess: '{slot} Changed Successfully',
    changeCredMessage: 'You can login again using your new identity',
  },
  account: {
    segs: 'Sex',
    detail: 'Account Details',
    occupation: 'Occupation',
    myPoints: 'My Points',
    pointHistory: 'Point History',
    myCards: 'My Cards',
    referral: 'Referral',
    birthDate: 'Date of Birth',
    editSuccess: 'Profile Changed Successfully',
    editProfile: 'Edit Profile',
    changePhone: 'Change Phone Number',
    changeEmail: 'Change E-Mail',
    changePassword: 'Change Password',
    passwordChanged: 'Password Changed Successfully',
    entryVerified: '{slot} Verified',
    entryUnverified: '{slot} Unverified',
    profilePhoto: 'Profile Photo',
    profilePhotoChange: 'Change Profile Photo',
    profilePhotoChanged: 'Profile Photo Changed Successfully',
    profileDetails: 'Profile Details',
  },
  card: {
    add: 'Add Card',
    added: 'Card Added Successfully',
    title: 'My Cards',
    number: 'Card Number',
    notFound: 'Card not found',
    notMatch: 'Card type does not match, did you mean "{slot}" card?',
    activation: 'Card Activation',
    diffEmailTitle: 'Email on this card is different from your account email',
    diffEmailBody: 'The email registered on the card is <b>{slot}</b>',
    diffEmailSub:
      'Activation code will be sent to the email registered on the card. Make sure you can still access that email.',
    lengthError: 'Card number must be {slot} digits',
    whereNumber: 'Card number can be found at the back of the card',
  },
  errors: {
    genericTitle: 'An Error Occurred',
    genericWithRetry: 'An error occurred, please try again later.',
  },
  points: {
    allTransactions: 'All Transactions',
    transactionDate: 'Transaction Date',
    transactionType: 'Transaction Type',
    transactionSource: 'Transaction Source',
  },
  generic: {
    to: 'To',
    from: 'From',
    or: 'Or',
    back: 'Back',
    next: 'Next',
    open: 'Open',
    save: 'Save',
    name: 'Name',
    male: 'Male',
    done: 'Done',
    female: 'Female',
    later: 'Later',
    second: 'Second',
    resend: 'Resend',
    submit: 'Submit',
    close: 'Close',
    city: 'City',
    address: 'Address',
    district: 'District',
    province: 'Province',
    cancel: 'Cancel',
    change: 'Change',
    uploadPhoto: 'Upload Photo',
    willExpireOn: 'will expire on',
    toContinueTo: 'to continue to',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms and Conditions',
    minCharacters: 'Minimum {type} length {slot} characters',
    maxCharacters: 'Maximum {type} length {slot} characters',
  },
  login: {
    title: 'Login',
    withMyValue: 'with MyValue account',
    orLoginWith: 'or login with',
    accountVerif: 'Account Verification',
    forgotPassword: 'Forgot Password?',
    noEmptyPassword: 'Password cannot be empty',
    noEmptyUsername: 'E-Mail/Phone cannot be empty',
    dontHaveAccount: "Don't have MyValue account?",
    notRegisteredToast: 'Account not found, please create MyValue account first',
  },
  mapping: {
    title: 'Connect Account',
    titleTemplate: 'Connect {slot} with MyValue',
    invalid: 'Account connection data is not valid',
    subtitle: 'We found your account on MyValue, connect your {slot} account and get more benefits',
    connect: 'Connect With MyValue',
    loginFirst: 'Please login first to connect your MyValue account before continuing',
    benefitOne: 'Get points by being active on {slot} and Kompas Gramedia',
    benefitTwo: 'Easier access to various Kompas Gramedia services with MyValue',
    finishTitle: 'Connecting your account...',
    finishSubtitle: 'The page will automatically move, do not close this page',
    finishNextCase:
      'Account has been connected, next you can login with MyValue account.<br/><b>Press continue to return to {slot} page</b>.',
  },
  referral: {
    create: 'Create Referral Code',
    createSuccess: 'Referral Code Created Successfully',
    createMessage: 'You can create a code that is as attractive as possible',
    emptyState:
      'Start collecting your Value Friends by sharing your referral code.<br /><a href="{slot}">Terms and Conditions Apply</a>',
    codeNotFound: 'Referral code not found',
  },
  register: {
    title: 'Register',
    success: 'Registration Successful',
    withOauth: 'Account registered with {slot}',
    successMessage: 'Please verify your account to get the full benefits of MyValue',
    orRegisterWith: 'or register with',
    haveAccount: 'Already have MyValue account?',
    errorUnameBlank: 'E-Mail/Phone Number cannot be empty',
    errorUnameInvalid: 'Invalid E-Mail/Phone Number format',
    errorEmailBlank: 'Email cannot be empty',
    errorNameBlank: 'Name cannot be empty',
    errorPhoneBlank: 'Phone number cannot be empty',
    errorPasswordLength: 'Password must be at least {slot} characters',
    errorPasswordMismatch: 'Password does not match',
    labelHP: 'Phone Number',
    labelEmail: 'E-Mail',
    labelFullname: 'Full Name',
    labelReferral: 'Referral Code',
    labelConfirmPass: 'Confirm Password',
    newPasswordSuccess: 'Password created successfully',
    newPasswordSuccessMessage: 'You can use your new password to login to your MyValue account.',
    acceptTnC:
      'By clicking the Register button, I agree to the <a href="{slot}" target="_blank" style="color: {color}" noreferrer noopener>Terms and Conditions and Privacy Policy</a> of the MyValue application and agree to receive emails containing information and offers from MyValue.',
  },
  verify: {
    now: 'Verify Now',
    later: 'Verify Later',
    email: 'Verify E-Mail',
    phone: 'Verify Phone Number',
    success: 'Account Verified',
    successMessage: 'Now you can enjoy all the benefits of your MyValue account.',
    verificationSent: 'Verification code sent',
    verificationResent: 'Verification code resent',
    verificationMessage:
      'Verification code has been sent to email <b class="text-primary">{slot}</b>. Enter the code to continue.',
    linkFormTitle: 'One more step to verify your account',
    linkWithReward: 'Reward Details will be sent to this email',
    linkWithRewardTitle: 'Check your email now for a chance to get a prize',
    linkWithRewardSubtitle:
      'Thank you, your number has been verified. We have sent an email to your account, please check your email for more information.',
  },
  $vuetify,
};

export default en;
