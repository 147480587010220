import type { I18NDictionary } from './types';

import id from './id';
import en from './en';

const messages = {
  id,
  en,
} as Record<'id' | 'en', I18NDictionary>;

export default messages;
